import { addJsScript } from '../../core/js/add-content-functions.js';
import { widgetLazyLoader } from '../../core/js/lazy-loading.js';

const mmScript =
  'https://mm.aftenposten.no/tools/front-widgets/widgets/dist/app-mmbox.js';

const loadBox2Widget = (): void => {
  window.addEventListener('load', () => {
    widgetLazyLoader('.mm-box2-container', () => {
      addJsScript(mmScript, () => {
        window.initMMBoxes('ap', 'box2');
        widgetLazyLoader('.mm-box3-container', () => {
          window.initMMBoxes('ap', 'box3');
        });
      });
    });
  });
};

const loadBox3Widget = (): void => {
  window.addEventListener('load', () => {
    widgetLazyLoader('.mm-box3-container', () => {
      addJsScript(mmScript, () => {
        window.initMMBoxes('ap', 'box3');
      });
    });
  });
};

export { loadBox2Widget, loadBox3Widget };
